import React from 'react'
import { CompanyDetailsCard } from '../../../../../stories/CompanyDetailsCard'
import { CompanyDataPoint } from '../../CompanyDataPoint'
import { CompanyData } from '../../../../../logic/Backend'
import { year, rangeString, flagAndCountryName, CountryFormat, liType } from '../../../../../logic/ValueFormatters'
import { OPERATING_STATUS_MAP } from '../../../../../resources/reference_data/operating_statuses'
import styles from './BasicsCard.module.scss'
import InstagramCard from '../../../../../stories/InstagramCard'
import CompanyNewsCard from '../../CompanyNews'

interface BasicsCardProps {
  company?: CompanyData
  t: (key: string) => string
  companyFormattedAddress: () => string
  news: any
  LinkedinCard: () => JSX.Element
  DescriptionCard: () => JSX.Element
}

export const BasicsCard: React.FC<BasicsCardProps> = ({ company, t, companyFormattedAddress, news, LinkedinCard, DescriptionCard }) => {
  const CompanyBasicsCard = () => (
    <CompanyDetailsCard title={t('Basics')} className={styles.basicsDataPointsTitle}>
      <div className={styles.basicsDataPoints}>
        <CompanyDataPoint label={t('Founded in')} value={year(company?.founded_on_date, 'n/a')} />
        <CompanyDataPoint label={t('Employee Range')} value={rangeString(company?.employee_count_int4range, undefined, 'n/a')} />
        <CompanyDataPoint label={t('Country')} value={flagAndCountryName(company?.country_iso_3166_1_alpha_3, CountryFormat.Code) ?? 'n/a'} />
      </div>
      <CompanyDataPoint label={t('Operating Status')} value={company?.status_enum ? OPERATING_STATUS_MAP[company?.status_enum] : 'n/a'} />
      <CompanyDataPoint label={t('Company Type')} value={liType(company?.li_type_enum, 'n/a')} />
      <CompanyDataPoint label={t('Address')} value={companyFormattedAddress()} />
    </CompanyDetailsCard>
  )

  const leftCardWidth = 42
  return (
    <div className={styles.basicsContainer}>
      <div className={styles.basicsTopRow}>
        <CompanyBasicsCard />
        {CompanyNewsCard(leftCardWidth, news)}
      </div>
      <div className={styles.basicsBottomRow}>
        <div className={styles.basicsLeftColumn}>
          <LinkedinCard />
          <InstagramCard company={company} />
        </div>
        <div className={styles.basicsRightColumn}>
          <DescriptionCard />
        </div>
      </div>
    </div>
  )
}
