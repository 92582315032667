import React, { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import styles from './Tabs.module.scss'
import { CompanyData } from '../../../../../logic/Backend'
import { InsightsDataType } from '../../Insights'
import { SimilarCompaniesType } from '../../SimilarCompanies'

interface TabsProps {
  t: (key: string) => string
  hash: string
  company?: CompanyData
  insightsData: InsightsDataType
  similarCompanies?: (CompanyData & SimilarCompaniesType)[]
}

function Tab(label: string, hash_: TabFragment, thingsToDisplay?: any[]): ReactElement {
  const hash = window.location.hash
  if (thingsToDisplay?.some((t) => t != null) ?? true) {
    return (
      <Link to={{ hash: hash_ }} className={hash_ === hash ? styles.current : undefined}>
        {label}
      </Link>
    )
  } else {
    return <span className={styles.disabled}>{label}</span>
  }
}

export const Tabs: FC<TabsProps> = ({ t, hash, company, insightsData, similarCompanies }) => {
  return (
    <ul className={styles.tabs}>
      <li>
        {Tab(t('Kurrant.ai Insights'), '#insights', [
          insightsData.sub_region,
          insightsData?.region,
          insightsData?.industries,
          insightsData?.revenue_stream,
          insightsData?.sales_target,
        ])}
      </li>
      <li>{Tab(t('Basics'), '')}</li>
      <li>{Tab(t('Key Contacts'), '#keyContacts', [company?.people])}</li>
      <li>
        {Tab(t('Growth'), '#growth', [
          company?.tech_spent_bigint_usd,
          company?.ig_followers_count_integer,
          company?.bw_revenue_usd_bigint,
          company?.li_followers_count_integer,
          company?.li_employee_count_integer,
        ])}
      </li>
      <li>
        {Tab(t('Funding & Financials'), '#fundingAndFinancials', [
          company?.investors,
          company?.funding_round_count_integer,
          company?.latest_funding_type,
          company?.exit_count_integer,
          company?.total_assets_bigint_usd,
          company?.revenue_bigint_usd,
          company?.net_income_bigint_usd,
          company?.operating_income_bigint_usd,
        ])}
      </li>
      <li>{Tab('Similar Companies', '#similarCompanies', [similarCompanies])}</li>
      <li>{Tab(t('Notes'), '#notes')}</li>
    </ul>
  )
}
type TabFragment = '' | '#keyContacts' | '#growth' | '#fundingAndFinancials' | '#insights' | '#similarCompanies' | '#notes'
