import React from 'react'
import styles from './GrowthCard.module.scss'
import { CompanyDetailsCard } from '../../../../../stories/CompanyDetailsCard'
import { CompanyDataPoint } from '../../CompanyDataPoint'
import { Latest6MonthsChart } from '../../../../../stories/Latest6MonthsChart'
import { internationalUnits } from '../../../../../logic/ValueFormatters'
import { CompanyData } from '../../../../../logic/Backend'

interface GrowthCardProps {
  company: CompanyData | undefined
  t: (key: string) => string
}

export const GrowthCard: React.FC<GrowthCardProps> = ({ company, t }) => {
  return (
    <div className={styles.growthContainer}>
      <CompanyDetailsCard
        title={t('Growth')}
        thingsToDisplay={[company?.tech_spent_bigint_usd, company?.ig_followers_count_integer]}
        naLinkMessage={'Visit LinkedIn'}
        naLinkUrl={company?.linkedin_url}
      >
        <div className={styles.growthDataPointsGrid}>
          <CompanyDataPoint label={t('Tech Spend')} value={internationalUnits(company?.tech_spent_bigint_usd, 1, '$', 'n/a')} />
          <CompanyDataPoint label={`Instagram ${t('Followers')}`} value={internationalUnits(company?.ig_followers_count_integer, 1, '', 'n/a')} />
        </div>
      </CompanyDetailsCard>
      <CompanyDetailsCard
        thingsToDisplay={[company?.bw_revenue_usd_bigint, company?.li_followers_count_integer, company?.li_employee_count_integer]}
        naTitle={'Graph'}
        naLinkMessage={'Visit LinkedIn'}
        naLinkUrl={company?.linkedin_url}
      >
        <div className={styles.growthChartGrid}>
          <Latest6MonthsChart
            label={t('Estimated Sales Revenue')}
            latestValue={company?.bw_revenue_usd_bigint}
            momValue={company?.bw_revenue_mom_decimal}
            values={company?.bw_revenue_latest_6_months}
            unit={'$'}
          />
          <Latest6MonthsChart
            label={`Linkedin ${t('Followers')}`}
            latestValue={company?.li_followers_count_integer}
            momValue={company?.li_followers_count_mom_decimal}
            values={company?.li_followers_count_latest_6_months}
          />
          <Latest6MonthsChart
            label={`Linkedin ${t('Employees')}`}
            latestValue={company?.li_employee_count_integer}
            momValue={company?.li_employee_count_mom_decimal}
            values={company?.li_employee_count_latest_6_months}
          />
        </div>
      </CompanyDetailsCard>
    </div>
  )
}
