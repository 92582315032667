import React from 'react'
import Masonry from 'react-masonry-css'
import { KeyContact } from '../../../../../stories/KeyContact'
import { CompanyData } from '../../../../../logic/Backend'

interface KeyContactsCardProps {
  company: CompanyData | undefined
}

export const KeyContactsCard: React.FC<KeyContactsCardProps> = ({ company }) => {
  return (
    <Masonry breakpointCols={3} className="key-contacts-grid" columnClassName="key-contacts-grid-column">
      {company?.people?.map((employee, i) => (
        <KeyContact key={i} {...employee} />
      ))}
    </Masonry>
  )
}
