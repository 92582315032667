import React from 'react'
import { CompanyDetailsCard } from '../../../../../stories/CompanyDetailsCard'
import { CbLiOrPlaceholderImage } from '../../CbLiOrPlaceholderImage'
import { LinkedinEmployee } from '../../../../../stories/LinkedinEmployee'
import { internationalUnits, liType, linkFromUrlOrDomain } from '../../../../../logic/ValueFormatters'
import styles from './LinkedinCard.module.scss'
import { CompanyData } from '../../../../../logic/Backend'

interface LinkedinCardProps {
  company: CompanyData
  t: (key: string) => string
  localization: string
  linkedinUrl: string
}

export const LinkedinCard: React.FC<LinkedinCardProps> = ({ company, t, localization, linkedinUrl }) => {
  return (
    <CompanyDetailsCard
      title={'LinkedIn'}
      thingsToDisplay={[
        localization === 'jp' ? company?.name_jp : company?.li_name,
        company?.li_logo_url,
        company?.li_type_enum,
        company?.li_followers_count_integer,
        company?.li_employee_list,
        company?.li_employee_count_integer,
        company?.linkedin_url,
      ]}
      naLinkMessage={'Visit LinkedIn'}
      naLinkUrl={company?.linkedin_url}
      className={styles.linkedinCard}
    >
      {company?.li_name && (
        <>
          <div className={styles.linkedinHeaderContainer}>
            <CbLiOrPlaceholderImage li_logo_url={company.li_logo_url} altText={'Logo'} size={70} className={styles.linkedinHeaderLogo} />
            <div className={styles.linkedinInfoContainer}>
              {company?.li_name && <span className={styles.linkedinName}>{company?.li_name}</span>}
              {company?.li_type_enum != null && (
                <span>
                  {liType(company?.li_type_enum)} • {internationalUnits(company?.li_followers_count_integer, 1, '', 'n/a')} {t('Followers')}
                </span>
              )}
              {linkedinUrl && (
                <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
                  {linkedinUrl}
                </a>
              )}
            </div>
          </div>
          {company?.li_employee_list && (
            <>
              <hr className={styles.linkedinDivider} />
              <div>
                <span className={styles.employeeCount}>{internationalUnits(company?.li_employee_count_integer, 1, '', 'n/a')}</span>{' '}
                {t('Employees on LinkedIn')}
              </div>
              <div className={styles.linkedinEmployeeGrid}>
                {company?.li_employee_list?.map((employee, i) => (
                  <LinkedinEmployee
                    key={i}
                    profile_url={employee.profile_url}
                    profile_picture_url={employee.profile_picture_url}
                    role={employee.role}
                    name={employee.name}
                  />
                ))}
              </div>
              {company?.linkedin_url && (
                <a href={linkFromUrlOrDomain(company?.linkedin_url)} target="_blank" rel="noopener noreferrer" className={styles.viewMoreEmployees}>
                  {t('View More Employees')}
                </a>
              )}
            </>
          )}
        </>
      )}
    </CompanyDetailsCard>
  )
}
