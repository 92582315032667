import React, { CSSProperties, FC, ReactNode } from 'react'
import { ButtonProps } from '@blueprintjs/core'
import noDataImage from '../resources/images/no-data.png'

export interface CompanyDetailsCardProps extends ButtonProps {
  children: ReactNode
  title?: string
  thingsToDisplay?: unknown[]
  naTitle?: string
  naLinkMessage?: string
  naLinkUrl?: string
  flat?: boolean
  style?: CSSProperties
  className?: string
}

export const CompanyDetailsCard: FC<CompanyDetailsCardProps> = ({
  title,
  children,
  thingsToDisplay,
  naTitle,
  naLinkMessage,
  naLinkUrl,
  flat,
  style,
  className,
}) => {
  const somethingToDisplay = thingsToDisplay?.some((t) => t != null) ?? true
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection: somethingToDisplay ? 'column' : 'row',
        gap: '16px',
        backgroundColor: 'white',
        ...(!flat && { boxShadow: '0 2px 4px 1px RGBA(233, 233, 233, 0.75)', padding: '24px' }),
        ...(!somethingToDisplay && { alignItems: 'center' }),
        ...style,
      }}
    >
      {somethingToDisplay ? (
        <>
          {title && <h2 style={{ margin: '0', color: 'black', fontSize: '22px', fontWeight: '500' }}>{title}</h2>}
          {children}
        </>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center', margin: '0 auto' }}>
          <img src={noDataImage} alt="No data" width={100} height={87} />
          <span>
            <strong>{title ?? naTitle ?? 'Data'}</strong> is unavailable at this time.
          </span>
          {naLinkMessage && naLinkUrl && (
            <a href={naLinkUrl} target="_blank" rel="noopener noreferrer">
              {naLinkMessage}
            </a>
          )}
        </div>
      )}
    </div>
  )
}
