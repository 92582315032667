import React from 'react'
import { CompanyDetailsCard } from '../../../../../stories/CompanyDetailsCard'
import { CompanyDataPoint } from '../../CompanyDataPoint'
import { FUNDING_TYPES } from '../../../../../resources/reference_data/funding_types'
import { internationalUnits, totalFundingRecap, year } from '../../../../../logic/ValueFormatters'
import styles from './FundingAndFinancialsCard.module.scss'
import { CompanyData } from '../../../../../logic/Backend'

interface FundingAndFinancialsCardProps {
  company: CompanyData | undefined
  t: (key: string) => string
}

export const FundingAndFinancialsCard: React.FC<FundingAndFinancialsCardProps> = ({ company, t }) => {
  return (
    <div className={styles.fundingContainer}>
      <CompanyDetailsCard
        title={t('Financials')}
        thingsToDisplay={[company?.total_assets_bigint_usd, company?.net_income_bigint_usd, company?.revenue_bigint_usd, company?.operating_income_bigint_usd]}
      >
        <div className={styles.financialsGrid}>
          <CompanyDataPoint label={t('Total Assets')} value={internationalUnits(company?.total_assets_bigint_usd, 1, '$', 'n/a')} />
          <CompanyDataPoint label={t('Net Income')} value={internationalUnits(company?.net_income_bigint_usd, 1, '$', 'n/a')} />
          <CompanyDataPoint label={t('Revenue')} value={internationalUnits(company?.revenue_bigint_usd, 1, '$', 'n/a')} />
          <CompanyDataPoint label={t('Operating Income')} value={internationalUnits(company?.operating_income_bigint_usd, 1, '$', 'n/a')} />
        </div>
      </CompanyDetailsCard>

      <CompanyDetailsCard
        title={t('Funding')}
        thingsToDisplay={[company?.latest_funding_type, company?.latest_funding_amount_bigint, company?.latest_funding_on_date, company?.exit_count_integer]}
      >
        <div className={styles.fundingGrid}>
          <CompanyDataPoint label={t('Total Funding')} value={totalFundingRecap(company)} />
          <CompanyDataPoint
            label={t('Latest Funding')}
            value={
              (company?.latest_funding_type ? FUNDING_TYPES[company?.latest_funding_type] : '') +
              (company?.latest_funding_type && company?.latest_funding_amount_bigint ? ' - ' : '') +
              internationalUnits(company?.latest_funding_amount_bigint, 1, '$') +
              ((company?.latest_funding_type || company?.latest_funding_amount_bigint) && company?.latest_funding_on_date ? ' in ' : '') +
              year(company?.latest_funding_on_date) +
              (!company?.latest_funding_type && !company?.latest_funding_amount_bigint && !company?.latest_funding_on_date ? 'n/a' : '')
            }
          />
          <CompanyDataPoint label={t('Exit Round')} value={company?.exit_count_integer?.toString() ?? 'n/a'} />
        </div>
        <CompanyDataPoint label={t('Investors')}>
          <ul className={styles.investorsList}>{company?.investors?.split(', ')?.map((inv, i) => <li key={i}>{inv}</li>) ?? 'n/a'}</ul>
        </CompanyDataPoint>
      </CompanyDetailsCard>
    </div>
  )
}
