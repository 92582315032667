import React, { FC, ReactElement, useEffect, useState } from 'react'
import styles from './index.module.scss'
import * as Backend from '../../../logic/Backend'
import { CompanyData, companyPage, getNews, Indexed } from '../../../logic/Backend'
import * as Routes from '../../../logic/Routes'
import { hrefForId, useQueryParams } from '../../../logic/Routes'
import { companyLink, linkedinUrlNormalization, withoutHighlight } from '../../../logic/ValueFormatters'
import { TopBar } from '../../components/TopBar'
import { Cache, Set } from '../Standard'
import { BreadcrumbProps } from '@blueprintjs/core'
import { useLocation } from 'react-router'
import RightSidebar from './RightSidebar'
import { InsightsDataType } from './Insights'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { SimilarCompaniesType } from './SimilarCompanies'
import { Header } from './CompanyDetailComponents/Header/Header'
import { Tabs } from './CompanyDetailComponents/Tabs/Tabs'
import { CompanyBody, TabFragment } from './CompanyDetailComponents/CompanyBody/CompanyBody'

export interface CompanyPageProps {
  id: Indexed['id']
  cache?: Cache
  setCache: Set<Cache | undefined>
}

const CompanyPage: FC<CompanyPageProps & WithNamespaces & IncludeT> = (props) => {
  const [company, setCompany] = useState(props.cache?.doubleClickedCompany)
  const [news, setNews] = useState()
  const [similarCompanies, setSimilarCompanies] = useState<(CompanyData & SimilarCompaniesType)[] | undefined>()
  const { focusedListId, focusedListName } = useQueryParams(['focusedListId', 'focusedListName'])
  const localization = localStorage.getItem('language')
  const companyName = withoutHighlight(localization === 'jp' && company?.name_jp ? company?.name_jp : company?.name)

  useEffect(() => {
    const getCompany = async () => {
      if (!company) {
        setCompany(await companyPage(props.id))
      }
    }
    const getCompanyNews = async () => {
      if (company) {
        setNews(await getNews(company.id))
      }
    }
    getCompany()
    getCompanyNews()
  }, [company])
  const hash = useLocation().hash as TabFragment
  const { t } = props
  const companyBreadcrumb = (): BreadcrumbProps => {
    return {
      href: hrefForId(Routes.company, props.id),
      text: companyName,
      current: true,
    }
  }
  const listBreadcrumb = (focusedListId: string): BreadcrumbProps => {
    return {
      href: hrefForId(Routes.list, focusedListId),
      text: focusedListName,
    }
  }

  const breadcrumbs: BreadcrumbProps[] = focusedListId
    ? [Routes.lists, listBreadcrumb(focusedListId), companyBreadcrumb()]
    : [Routes.companies, companyBreadcrumb()]

  useEffect(() => {
    ;(async () => {
      if (company === undefined) {
        setCompany(await Backend.companyPage(props.id))
      }
      if (similarCompanies === undefined) {
        setSimilarCompanies(await Backend.similarCompanies(props.id))
      }
    })()
  }, [])

  const companyFormattedAddress = () => {
    if (company?.address == null) {
      return 'n/a'
    }
    return [company?.address, company?.city, company?.state, company?.postal_code].filter((el) => el != null).join(', ')
  }

  const linkedinUrl = linkedinUrlNormalization(company?.linkedin_url)
  const link = companyLink(company?.homepage, company?.domain)
  const pipelineStatus = company?.pipeline_status
  const organizationId = company?.id || null

  const insightsData: InsightsDataType = {
    sales_target: company?.sales_target_type,
    revenue_stream: company?.revenue_stream_type,
    industries: company?.industries,
    region: company?.region,
    sub_region: company?.sub_region,
  }

  return (
    <div className={styles.detailsPagecontainer}>
      <div className={styles.detailsPageMainContent}>
        <TopBar breadcrumbs={breadcrumbs} />
        <div className={styles.detailsPageContentWrapper}>
          <Header
            company={company}
            companyName={companyName}
            link={link}
            linkedinUrl={linkedinUrl}
            organizationId={organizationId}
            pipelineStatus={pipelineStatus}
            t={t}
          />
          <Tabs t={t} hash={hash} company={company} insightsData={insightsData} similarCompanies={similarCompanies} />
          <CompanyBody
            hash={hash}
            company={company}
            t={t}
            companyFormattedAddress={companyFormattedAddress}
            news={news}
            localization={localization}
            linkedinUrl={linkedinUrl}
            insightsData={insightsData}
            similarCompanies={similarCompanies}
            id={props.id}
          />
        </div>
      </div>
      <RightSidebar api={props.cache?.api} company={company} />
    </div>
  )
}

export default withNamespaces()(CompanyPage)
