import React from 'react'
import { CompanyDetailsCard } from '../../../../../stories/CompanyDetailsCard'
import { withoutHighlight } from '../../../../../logic/ValueFormatters'
import Markdown from 'markdown-to-jsx'
import styles from '../DescriptionCard/DescriptionCard.module.scss'
import { CompanyData } from '../../../../../logic/Backend'

interface DescriptionCardProps {
  company: CompanyData
  t: (key: string) => string
  localization: string | null
}

export const DescriptionCard: React.FC<DescriptionCardProps> = ({ company, t, localization }) => {
  return (
    <CompanyDetailsCard title={t('Description')} className={styles.descriptionCard}>
      <div>
        <Markdown>
          {(withoutHighlight(localization === 'jp' ? company?.description_jp : company?.description) ||
            withoutHighlight(localization === 'jp' ? company?.short_description_jp : company?.short_description)) ??
            'n/a'}
        </Markdown>
      </div>
    </CompanyDetailsCard>
  )
}
