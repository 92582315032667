import React, { FC } from 'react'
import styles from './CompanyBody.module.scss'
import { CompanyData } from '../../../../../logic/Backend'
import { BasicsCard } from '../BasicsCard/BasicsCard'
import { LinkedinCard } from '../LinkedinCard/LinkedinCard'
import { DescriptionCard } from '../DescriptionCard/DescriptionCard'
import { KeyContactsCard } from '../KeyContactsCard/KeyContactsCard'
import { GrowthCard } from '../GrowthCard/GrowthCard'
import { FundingAndFinancialsCard } from '../FundingAndFinancialsCard/FundingAndFinancialsCard'
import Insights, { InsightsDataType } from '../../Insights'
import SimilarCompanies, { SimilarCompaniesType } from '../../SimilarCompanies'
import CompanyNotes from '../../CompanyNotes'
import { Indexed } from '../../../../../logic/Backend'

interface CompanyBodyProps {
  hash: TabFragment
  company: CompanyData | undefined
  t: any
  companyFormattedAddress: () => string
  news: any
  localization: string | null
  linkedinUrl: string
  insightsData: InsightsDataType
  similarCompanies: (CompanyData & SimilarCompaniesType)[] | undefined
  id: Indexed['id']
}

export type TabFragment = '' | '#keyContacts' | '#growth' | '#fundingAndFinancials' | '#insights' | '#similarCompanies' | '#notes'

export const CompanyBody: FC<CompanyBodyProps> = ({
  hash,
  company,
  t,
  companyFormattedAddress,
  news,
  localization,
  linkedinUrl,
  insightsData,
  similarCompanies,
  id,
}) => {
  return (
    <div className={styles.body}>
      {hash === '' && (
        <BasicsCard
          company={company}
          t={t}
          companyFormattedAddress={companyFormattedAddress}
          news={news}
          LinkedinCard={() => <LinkedinCard company={company} t={t} localization={localization} linkedinUrl={linkedinUrl} />}
          DescriptionCard={() => <DescriptionCard company={company} t={t} localization={localization} />}
        />
      )}
      {hash === '#keyContacts' && <KeyContactsCard company={company} />}
      {hash === '#growth' && <GrowthCard company={company} t={t} />}
      {hash === '#fundingAndFinancials' && <FundingAndFinancialsCard company={company} t={t} />}
      {hash === '#insights' && <Insights insightsObject={insightsData} />}
      {hash === '#similarCompanies' && <SimilarCompanies id={id} companies={similarCompanies} />}
      {hash === '#notes' && <CompanyNotes companyId={id} />}
    </div>
  )
}
